import { Component } from '@angular/core';

@Component({
  selector: 'tech-stack',
  template: `
    <div class="tech-stack-container">
      <h2>and this is my current tech stack 👇</h2>

      <article>
        <h3>front end<span>:</span></h3>
        <p style="border-color: #D2002F;">Angular</p>
        <p style="border-color: transparent;">and</p>
        <p style="border-color: #2F72BC;">Typescript</p>
      </article>

      <article>
        <h3>back end<span>:</span></h3>
        <p style="border-color: #69AC40;">Spring Boot</p>
        <p style="border-color: transparent;">and</p>
        <p style="border-color: #0D89C6;">Java</p>
      </article>

      <article>
        <h3>databases<span>:</span></h3>
        <p style="border-color: #E11A22;">Oracle</p>
        <p style="border-color: transparent;">and</p>
        <p style="border-color: #F2C22F;">Firebase</p>
      </article>

      <article>
        <h3>tools<span>:</span></h3>
        <p style="border-color: #F2F2F2;">PureRef,</p>
        <p style="border-color: #39D353;">GitHub,</p>
        <p style="border-color: #0077D7;">Azure DevOps,</p>
        <p style="border-color: #73B312;">cmder,</p>
        <p style="border-color: #195BA8;">IntelliJ,</p>
        <p style="border-color: transparent;">and</p>
        <p style="border-color: #31A0EB;">VS Code</p>

      </article>
    </div>
  `,
  styleUrls: ['./tech-stack.component.scss']
})
export class TechStackComponent {}
