import { Component } from '@angular/core';

@Component({
  selector: 'projects',
  template: `
    <section class="projects-container">
      <h2>and these are some of my projects 👇</h2>

      <h3 class="border-top">jb long and son body shop<span>.</span></h3>

      <a class="project-link" href="https://jblongandsonbodyshop.com/" target="_blank">
        <span>jblongandsonbodyshop.com</span> 🚗🚚🛠🔧
      </a>

      <a class="project-link" href="https://manager.jblongandsonbodyshop.com/" target="_blank">
        👨‍💼📰<span>manager.jblongandsonbodyshop.com</span> 🚗🤞
      </a>

      <h3 class="border-top">cloud harbour<span>.</span></h3>

      <a class="project-link" href="https://cloud-harbour.matthewlong.io/" target="_blank">
        ☁ <span>cloud-harbour.matthewlong.io</span> 👈 pretty big work in progress
      </a>

      <h3 class="border-top">mini projects<span>.</span></h3>

      <a class="project-link" href="https://markdown-viewer.matthewlong.io/" target="_blank">
        <span>markdown-viewer.matthewlong.io</span> ✅
      </a>

      <a class="project-link" href="https://pixel-art.matthewlong.io/" target="_blank">
        <span>pixel-art.matthewlong.io</span> :]
      </a>

      <a class="project-link" [routerLink]="['/color-shader']" routerLinkActive="router-link-active">
        🔴🟢🔵 <span>color-shader</span> 🧮
      </a>
    </section>  
  `,
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent { }
