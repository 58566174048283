import { Component, OnInit } from '@angular/core';
import { SocialPlatform, SocialPlatformBuilder } from '../../models/socials.model';

@Component({
  selector: 'dotio-socials',
  template: `
    <section>
      <a *ngFor="let social of socials" href="{{social.url}}" target="_blank">
        <img src="{{social.icon}}" alt="{{social.name}}">
      </a>
    </section>
  `,
  styleUrls: ['./socials.component.scss']
})
export class SocialsComponent implements OnInit {
  public socials: Array<SocialPlatform> = [];

  ngOnInit(): void {
    const twitter: SocialPlatform = new SocialPlatformBuilder()
      .name(`Twitter`)
      .url(`https://twitter.com/gnasch29`)
      .icon(`assets/icons/twitter.png`)
      .build();

    const github: SocialPlatform = new SocialPlatformBuilder()
      .name(`Github`)
      .url(`https://github.com/gnasch29`)
      .icon(`assets/icons/github.png`)
      .build();

    const discord: SocialPlatform = new SocialPlatformBuilder()
      .name(`Discord`)
      .url(`https://discordapp.com/users/117410919675461633`)
      .icon(`assets/icons/discord.png`)
      .build();

    this.socials = [];
    this.socials.push(twitter, github, discord);
  }
}
