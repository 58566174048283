import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { MatthewLongModule } from './app/matthewlong.module';

enableProdMode();
platformBrowserDynamic()
  .bootstrapModule(MatthewLongModule)
  .catch((err: any): void => {
    console.error('¯\\_(ツ)_/¯');
    console.error(err);
  })
  .finally((): void => {
    console.log('(⊙_⊙)');
  });
