import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { MatthewLongComponent } from './components/matthewlong/matthewlong.component';
import { ColorShaderComponent } from './components/color-shader/color-shader.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { SocialsComponent } from './components/socials/socials.component';
import { TechStackComponent } from './components/tech-stack/tech-stack.component';

const routes: Routes = [
  { path: 'socials', component: SocialsComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'color-shader', component: ColorShaderComponent },
  { path: 'stack', component: TechStackComponent },
  { path: '', redirectTo: '/projects' },
  { path: '**', redirectTo: '' }
];

@NgModule({
  declarations: [
    ColorShaderComponent,
    MatthewLongComponent,
    SocialsComponent,
    ProjectsComponent,
    TechStackComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
  ],
  bootstrap: [
    MatthewLongComponent
  ]
})
export class MatthewLongModule { }
