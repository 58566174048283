import { Component } from '@angular/core';
import { DateTime } from 'luxon';
import { interval, Observable, of, switchMap } from 'rxjs';

@Component({
  template: `
    <div class="container">
      <header>
        <h1>hello. i'm <span [routerLink]="['/']" routerLinkActive="router-link-active">Matthew Long</span>.</h1>
      </header>

      <main>
        <router-outlet></router-outlet>
      </main>

      <footer>
        <nav>
          <a class="link" [routerLink]="['/projects']" [routerLinkActive]="'is-active'"><span>projects.</span></a>
          <a class="link" [routerLink]="['/socials']" [routerLinkActive]="'is-active'"><span>socials.</span></a>
          <a class="link" [routerLink]="['/stack']" [routerLinkActive]="'is-active'"><span>stack.</span></a>
        </nav>

        <span *ngIf="!showZulu" (click)="showZulu = true" class="local">
          {{ local$ | async | date:'medium' }}<ng-container *ngIf="(local$ | async)"> Local</ng-container>
        </span>
        <span *ngIf="showZulu" (click)="showZulu = false" class="zulu">
          {{ zulu$ | async | date:'medium':'UTC' }}<ng-container *ngIf="(zulu$ | async)"> Zulu</ng-container>
        </span>
        <span *ngIf="!(local$ | async) && !(zulu$ | async)" class="loading">
          {{ loading$ | async }}
        </span>
      </footer>
    </div>
  `,
  styleUrls: ['./matthewlong.component.scss'],
  selector: 'matthewlong-io'
})
export class MatthewLongComponent {
  public showZulu: boolean = false;

  public local$: Observable<DateTime> = interval(1000).pipe(switchMap((): Observable<DateTime> => of(DateTime.now())));

  public zulu$: Observable<DateTime> = interval(1000).pipe(switchMap((): Observable<DateTime> => of(DateTime.now().toUTC())));

  public loading$: Observable<String> = interval(200).pipe(switchMap((x: number): Observable<String> => {
    return of(`${'.'.repeat(x % 10)}loading`);
  }));
}
